.nav {
    font-size: 16px;
    text-transform: none;

    @include max-screen($md-max) {
        display: table;
        width: 100%;
        height: 100%;
        text-align: center;
        vertical-align: middle;
        margin: $clear-margin;
        padding: $clear-padding;
    }

    @include max-screen($md-max) {
        font-size: 24px;
    }

    @include max-screen($sm-max) {

    }

    @include max-screen($xs-max) {

    }

    @include max-screen($xxs-max) {
        font-size: 18px;
    }

    &__list {
        @extend .flex, .flex--center-y;

        @include max-screen($md-max) {
            margin: $clear-margin;
            padding: 200px 20% 50px;
            text-align: center;
            display: table-cell;
            text-align: center;
            vertical-align: top;
            width: 100%;
            height: 100%;

            @include max-screen($xs-max) {
                padding-left: 100px;
                padding-right: 100px;
            }

            @include max-screen($xxs-max) {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }

    &__item {
        position: relative;
        margin-right: 50px;

        @include max-screen($md-max) {
            margin: $clear-margin;
            opacity: 0;
            transform: translateX(50px);
            @include transition;

            .is-menu-open & {
                opacity: 1;
                transform: translateX(0);
                transition-delay: calc(300ms + (100ms * var(--li-index)));
            }

        }

        &:last-child {
            margin-right: 0;
        }

        [class*="ico-"] {
            display: inline-block;
            color: $key-color-1;
            @include transition;
            transform: rotate(0);
            font-size: 20px;
            margin-left: 10px;

            @include max-screen($sm-max) {
                font-size: 24px;
                padding: 0;
            }

            @include max-screen($xxs-max) {
                font-size: 20px;
            }

            @include hover() {
                color: $link-color-hover;

                @include max-screen($md-max) {
                    color: inherit;
                }
            }
        }

        &.is-hover {

            [class*="ico-"] {
                display: inline-block;
                @include transition;
                transform: rotate(180deg);
            }
        }

        > a {
            @extend .flex, .flex--center-y;
            //display: block;
            font-weight: $default-font-weight-bold;
            text-align: center;
            color: $default-font-color;
            padding: 10px 0;

            > i {
                margin-right: 13px;
            }

            @include max-screen($md-max) {
                font-size: 22px;
            }

            @include max-screen($xxs-max) {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            @include hover() {
                color: $key-color-1;

                @include max-screen($md-max) {
                    color: inherit;
                }
            }

            &.is-active {
                color: $key-color-1;

                @include max-screen($md-max) {
                    color: $white-color;
                }
            }
        }


    }

    &__icon {

        &.is-hover {
            display: inline-block;
            @include transition;
            transform: rotate(180deg);
        }
    }

    &__sublist {
        position: absolute;
        top: 100%;
        left: 0;
        border-radius: 0;
        overflow: hidden;
        background-color: #fff;
        min-width: 280px;
        display: none;
        box-shadow: $default-box-shadow;

        @include max-screen($md-max) {
            position: static;
            background-color: transparent;
            box-shadow: none;
            min-width: 0;
            height: 100%;
            border-radius: 0;
            //overflow: initial;
        }
    }

    &__subitem {


        a {
            border-bottom: 1px solid lighten($border-color, 10%);
            padding: 10px 20px;
            color: $white-color;
            //background-color: #fff;
            font-size: 14px;
            line-height: 1.2;
            min-height: 72px;
            display: flex;
            align-items: center;
            text-transform: initial;
            background-color: #041328;
            border-bottom: 1px solid rgba(171, 190, 216, .1);
            @include hover {
                background-color: $key-color-1;
                color: #fff;
            }

            @include max-screen($md-max) {
                border: none;
                font-size: 18px;
                background-color: transparent;
                text-align: left;
                color: $white-color;

                > img {
                    display: none;
                }
            }

            @include max-screen($xs-max) {
                padding: 8px 0;
                font-size: 15px;
            }

            &.is-active {
                background-color: $key-color-1;
                color: #fff;
            }
        }
    }

    &__subitem:last-child {

        a {
            border-bottom: none;
        }
    }
}
