// 
// PROJECT ICONS
// 

@font-face {
  font-family: $project-font-family-icon;
  src: url('../fonts/icons.ttf') format('truetype'),
  url('../fonts/icons.woff') format('woff'),
  url('../fonts/icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.ico {
  font-family: $project-font-family-icon !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-tooltip .path1:before {
  content: "\e921";
  color: rgb(142, 166, 184);
}

.ico-tooltip .path2:before {
  content: "\e937";
  margin-left: -36px;
  color: rgb(255, 255, 255);
}

.ico-close-icon:before {
  content: "\e924";
}

.ico-images-icon:before {
  content: "\e930";
}

.ico-option1:before {
  content: "\e931";
}

.ico-option2:before {
  content: "\e932";
}

.ico-user-icon-thick:before {
  content: "\e933";
}

.ico-kadruj-icon:before {
  content: "\e934";
}

.ico-osadz-icon:before {
  content: "\e935";
}

.ico-chevron-down-proj:before {
  content: "\e905";
}

.ico-long-arrow-left-icon:before {
  content: "\e906";
}

.ico-long-arrow-right-black-icon:before {
  content: "\e907";
}

.ico-chevron-up-proj:before {
  content: "\e908";
}

.ico-bell-icon:before {
  content: "\e909";
}

.ico-calendar-icon:before {
  content: "\e90a";
}

.ico-check-icon:before {
  content: "\e90b";
}

.ico-info-icon:before {
  content: "\e90c";
}

.ico-lock-icon:before {
  content: "\e90d";
}

.ico-trash-icon1:before {
  content: "\e90e";
}

.ico-upload-icon:before {
  content: "\e90f";
}

.ico-basket-icon:before {
  content: "\e911";
}

.ico-change-icon:before {
  content: "\e912";
}

.ico-download2-icon:before {
  content: "\e913";
}

.ico-download-icon:before {
  content: "\e914";
}

.ico-edit-icon:before {
  content: "\e915";
}

.ico-help-icon:before {
  content: "\e916";
}

.ico-image-icon:before {
  content: "\e917";
}

.ico-library-icon:before {
  content: "\e918";
}

.ico-licence-icon:before {
  content: "\e91a";
}

.ico-logo:before {
  content: "\e91b";
}

.ico-mail-icon:before {
  content: "\e91c";
}

.ico-phone-icon:before {
  content: "\e91d";
}

.ico-search-icon:before {
  content: "\e920";
}

.ico-trash-icon:before {
  content: "\e926";
}

.ico-user-icon:before {
  content: "\e928";
}

.ico-facebook-icon:before {
  content: "\e929";
}

.ico-instagram-icon:before {
  content: "\e92a";
}

.ico-twitter-icon:before {
  content: "\e92f";
}

.ico-facebook:before {
  content: "\e900";
}

.ico-instagram:before {
  content: "\e904";
}

.ico-file:before {
  content: "\e003";
}

.ico-email:before {
  content: "\e000";
}

.ico-technology:before {
  content: "\e001";
}

.ico-smartphone:before {
  content: "\e002";
}

.ico-interface:before {
  content: "\e903";
}

.ico-search:before {
  content: "\e004";
}

.ico-info-button:before {
  content: "\e005";
}

.ico-caution-sign:before {
  content: "\e006";
}

.ico-cancel-1:before {
  content: "\e007";
}

.ico-checked:before {
  content: "\e008";
}

.ico-shopping-cart:before {
  content: "\e009";
}

.ico-cancel:before {
  content: "\e010";
}

.ico-star:before {
  content: "\e011";
}

.ico-shopping-bag:before {
  content: "\e012";
}

.ico-arrow:before {
  content: "\e014";
}

.ico-arrow-bold:before {
  content: "\e015";
}

.ico-chevron-thin-up:before {
  content: "\e016";
}

.ico-chevron-thin-right:before {
  content: "\e017";
}

.ico-chevron-thin-left:before {
  content: "\e018";
}

.ico-chevron-thin-down:before {
  content: "\e019";
}

.ico-chevron-right:before {
  content: "\e020";
}

.ico-chevron-left:before {
  content: "\e021";
}

.ico-chevron-down:before {
  content: "\e022";
}

.ico-chevron-up:before {
  content: "\e023";
}

.ico-twitter:before {
  content: "\e936";
}

.ico-arrow-right:before {
  content: "\e92d";
}

.ico-chevron-right1:before {
  content: "\e92e";
}

.ico-chevron-left1:before {
  content: "\e901";
}

.ico-arrow-left:before {
  content: "\e902";
}

.ico-play2:before {
  content: "\e910";
}

.ico-check:before {
  content: "\e919";
}

.ico-skype:before {
  content: "\e91e";
}

.ico-youtube:before {
  content: "\e91f";
}

.ico-pen:before {
  content: "\e922";
}

.ico-close:before {
  content: "\e923";
}

.ico-repeat:before {
  content: "\e925";
}

.ico-play:before {
  content: "\e927";
}

.ico-ok:before {
  content: "\e92b";
}

.ico-user:before {
  content: "\e92c";
}

.ico-log-off {
  box-sizing: border-box;
  position: relative;
  display: block;
  border-radius: 16px;
  border: 2px solid;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 16px;
  border-top: 2px solid transparent;
  color: #ED9C2A;
}

.ico-log-off::before {
  content: "";
  display: block;
  color: #ED9C2A;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  height: 8px;
  width: 2px;
  background: currentColor;
  left: 5px;
  bottom: 6px;
}

.ico-trash {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 12px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px,
  inset -2px 0 0,
  inset 2px 0 0;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  margin-top: 4px
}

.ico-trash::after,
.ico-trash::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute
}

.ico-trash::after {
  background: currentColor;
  border-radius: 3px;
  width: 16px;
  height: 2px;
  top: -4px;
  left: -5px
}

.ico-trash::before {
  width: 10px;
  height: 4px;
  border: 2px solid;
  border-bottom: transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  top: -7px;
  left: -2px;
}