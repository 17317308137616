.passwords {

  form {
    max-width: 366px;
  }

  &--your-data {
    form {
      max-width: 100%;
    }
  }

  .progress-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .progress-title {
    font-size: 12px;
    color: $black-color;
    font-weight: $default-font-weight-semibold;
    margin-right: 12px;
  }

  span.dot {
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    border-radius: 50vw;

    margin-right: 11px;
  }


  .invoice-container {
    margin-top: 35px;

    @include max-screen($sm-max) {
        margin-top: 0;
    }
  }
}


