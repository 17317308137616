.select-container {
  width: 100%;
  @extend .flex, .flex--nowrap;
  align-items: center;
  justify-content: flex-start;

  @include max-screen($md-max) {
    margin-bottom: 20px;
  }

  &__additional {
    @extend .flex, .flex--nowrap;
    align-items: center;
    width: 100%;
    justify-content: flex-start;

    .select {
      width: 100%;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }

    @include max-screen($sm-max) {
      margin-bottom: 13px;

      .select:last-child {
        margin-right: 0;
      }
    }
  }

  @include max-screen($sm-max) {
    flex-direction: column-reverse;
  }

  .input-wrapper {
    display: flex;
    width: 50%;
    margin-right: 16px;

    @include max-screen($lg-max) {
      width: 255px;
    }


    @include max-screen($sm-max) {
      margin-bottom: 13px;
      width: 100%;
      margin-right: 0;
    }
  }

  .select {
    margin-right: 16px;
  }
}

/*


MATERIALS


 */

.materials {
  width: 100%;

  &--library {
    border-bottom: 2px solid $key-color-2;
    padding: 31px 0;

    &.last {
      border: none;
    }
  }


  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include max-screen($sm-max) {
      flex-direction: column;
    }
  }

  &__photo {
    margin-right: 46px;

    @include max-screen($sm-max) {
      width: 100%;
      margin: 0 0 25px 0;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &__info {
    max-width: 300px;
    margin-right: 40px;

    @include max-screen($sm-max) {
      margin-right: 0;
      max-width: initial;
      margin-bottom: 25px;
    }

    &-item {
      margin-bottom: 10px;
      font-weight: $default-font-weight-semibold;
    }
  }

  &__download-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 15px;
    color: $default-font-color;
    font-weight: $default-font-weight-bold;
    margin-bottom: 25px;
    @include transition();

    @include hover() {
      color: $key-color-5;
    }

    i {
      position: relative;
      top: 3px;
      min-width: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $key-color-5;
      margin-right: 13px;
    }

  }
}


.sidenav {
  width: 100%;
  padding-right: 40px;

  @include max-screen($lg-max) {
    padding-right: 0;
  }

  &__item {
    margin-bottom: 13px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19px 30px 18px;

    &--add-category {
      margin-bottom: 48px;

      i {
        margin-right: 5px;
        font-size: 10px;
      }
    }
  }

  &__info {
    font-size: 15px;
    color: $black-color;
    text-align: center;
    margin: 28px 0 13px 0;
  }


  .sidenav-select {
    display: none;

    @include max-screen($md-max) {
      display: block;
      width: 100%;
      margin-bottom: 20px;

      .select {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
