.fancybox-is-modal.fancybox-is-open .fancybox-slide {
  //padding: 0 15px;
}

.modal {
  padding: 0 !important;
  margin: 0 !important;
  background: #FFF;
  width: calc(100% - 30px);
  max-width: 823px;
  overflow: visible !important;
  border-radius: 0;
  display: none;

  form {
    width: 100%;
  }

  &__title {
    font-size: 25px;
    letter-spacing: -1.39px;
    color: $default-font-color;
    font-weight: $default-font-weight-bold;
  }

  &__subtitle {
    font-size: 20px;
    letter-spacing: -1.11px;
    color: $default-font-color;
    margin-bottom: 40px;

    @include max-screen($xxs-max) {
      margin-bottom: 30px;
    }
  }

  &__form {
    max-width: 317px;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    margin-bottom: 44px;

    &--right {
      align-items: center;
      @include max-screen($xxs-max) {
        margin-bottom: 20px;
      }

      .btn {
        margin-left: auto;
        margin-right: 30px;
        white-space: nowrap;


        @include max-screen($xxs-max) {
          display: none;
        }
      }


      .login-ask {
        margin-left: auto;
        margin-right: 13px;
        font-size: 14px;
        font-weight: $default-font-weight-semibold;
        color: $black-color;
        opacity: 0.8;
      }
    }

    &.with-login-ask {
      .btn {
        margin-left: 0;
      }
    }

    > .modal__title {
      margin-bottom: 0;
    }

  }

  &__close {
    @extend .flex, .flex--center-y, .flex--nowrap;
    position: absolute;
    top: -50px;
    right: -50px;
    flex-shrink: 0;
    color: #fff;
    font-size: 12px;
    @include transition();
    line-height: 1;
    font-weight: $default-font-weight;

    @include hover() {
      color: $key-color-1;
    }

    > span {
      font-size: 12px;
      font-weight: $default-font-weight;
      margin-right: 8px;
    }

    &-button {
      font-size: 25px;
      color: $default-font-color;

      i {
        position: relative;
        top: 3px;
      }
    }
  }

  &__heading {
    text-transform: uppercase;
    font-size: 13px;
    color: $key-color-1;
    margin-bottom: 10px;
    font-weight: $default-font-weight-bold;
  }

  &__inner {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include max-screen($xxs-max) {
      padding: 30px;
    }

  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include max-screen($xxs-max) {
      flex-direction: column;
    }

    .btn {
      margin-right: 16px;

      @include max-screen($xxs-max) {
        margin-right: 0;
        justify-content: center;
        margin-bottom: 5px;
        width: 100%;
      }
    }
  }

  //
  // MODAL OPENING CLOSING EFFECTS
  //
  .fancybox-is-modal & {
    transform: translateY(-50px);
    @include transition();
  }

  .fancybox-is-modal .fancybox-slide--current & {
    transform: translateY(0);
  }

  .fancybox-is-modal.fancybox-is-closing & {
    transform: translateY(100px);
  }

  &__photo {
    height: 100%;
    width: 100%;

    img {
      height: inherit;
      width: 100%;
      object-fit: cover;
    }

    @include max-screen($sm-max) {
      margin-bottom: 20px;
      height: auto;
    }
  }

  .limited-gallery {
    height: 230px;
    overflow-y: auto;

    @include max-screen($md-max) {
      height: 210px;
    }


  }

  .gallery {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    img {
      width: calc(33.3% - 9px);
      margin-right: 9px;
      margin-bottom: 9px;
      object-fit: cover;

      &:nth-child(3) {
        margin-right: 0;
      }
    }
  }
}


.temporary-triggers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  a {
    margin-right: 16px;
    margin-bottom: 10px;


    @include max-screen($xxs-max) {
      width: 100%;
      margin-right: 0;
    }
  }
}


.login-button {

  &--desktop {
    display: block;

    @include max-screen($xxs-max) {
      display: none;
    }
  }

  &--mobile {
    display: none;

    @include max-screen($xxs-max) {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}
