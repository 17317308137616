.select2-selection--multiple {
    background-color: #fff;
    border: 2px solid $key-color-2;
    border-radius: 0;
    @include transition();
    cursor: text;

    .select2-selection__rendered {
        box-sizing: border-box;
        list-style: none;
        margin: 0;
        padding: 0 18px;
        width: 100%;

        li {
            list-style: none;
        }
    }

    .select2-selection__placeholder {
        color: $key-color-1;
        opacity: 0.3;

        margin-top: 5px;

        float: left;
    }

    .select2-selection__clear {
        cursor: pointer;
        float: right;
        font-weight: bold;
        margin-top: 5px;
        margin-right: 10px;
    }

    .select2-selection__choice {
        background-color: rgba($key-color-1, 0.1);
        border: 1px solid rgba($key-color-1, 0.1);
        border-radius: 0;
        color: $key-color-1;
        cursor: default;
        position: relative;
        font-size: 13px;
        float: left;
        margin-right: 5px;
        margin-top: 11px; 
        padding: 3px 33px 4px 8px;
        font-weight: $default-font-weight-bold;
        @include truncate(100%);
        display: block;
    }

    .select2-selection__choice__remove {
        @extend .flex, .flex--center-x, .flex--center-y;
        color: $key-color-1;
        cursor: pointer;
        font-weight: normal;
        font-size: 24px;
        line-height: 1;
        position: absolute;
        top: -2px;
        right: 10px;
        @include transition();
        transform-origin: center;

        &:hover {
            color: $key-color-1;
            opacity: 0.6;
        }
    }
}

&[dir="rtl"] {
    .select2-selection--multiple {

        .select2-selection__choice,
        .select2-selection__placeholder,
        .select2-search--inline {
            float: right;
        }

        .select2-selection__choice {
            margin-left: 5px;
            margin-right: auto;
        }

        .select2-selection__choice__remove {
            margin-left: 2px;
            margin-right: auto;
        }
    }
}

&.select2-container--focus:not(.select2-container--disabled),
&.select2-container--open:not(.select2-container--disabled) {
    .select2-selection--multiple {
        border-color: $key-color-1;
        box-shadow: 0 0 15px 0 rgba($key-color-1, 0.1);
    }
}

&.select2-container--focus {
    .select2-selection--multiple {
        outline: 0;
    }
}

&.select2-container--disabled {
    .select2-selection--multiple {
        background-color: #f2f2f2;
        border-color: #d9d9d9;
        cursor: default;
    }

    .select2-selection__choice {
        padding-left: 8px;
        background-color: #d9d9d9;
        border-color: #d9d9d9;
        color: #a8a8a8;
    }

    .select2-selection__choice__remove {
        display: none;
    }
}
