.menu-trigger {
    flex-shrink: 0;
	display: none;
	width: 50px;
	height: 50px;
	position: relative;
	cursor: pointer;
	float: right;
	overflow: hidden;
	border-radius: 0;
	background: transparent;
	@include transition(all,.5s,ease-in-out);
	@include rotate(0);
    z-index: 901;
	
	&__inner {
		margin: 12px;
		position: relative;
		display: block;
		width: 26px;
		height: 26px;
		
		span {
			display: block;
			position: absolute;
			height: 4px;
			width: 50%;
			background: $key-color-1;
			opacity: 1;
			@include transition(all,.25s,ease-in-out);
			@include rotate(0);
			&:nth-child(even) {
				right: 0;
				border-radius: 0 4px 4px 0;
			}
			&:nth-child(odd) {
				left: 0;
				border-radius: 4px 0 0 4px;
			}
			&:nth-child(1),
			&:nth-child(2) {
				top: 2px;
			}
			&:nth-child(3),
			&:nth-child(4) {
				top: 11px;
			}
			&:nth-child(4) {
				right: auto;
				left: 50%;
			}
			&:nth-child(5),
			&:nth-child(6) {
				top: 20px;
			}
		}
	}
    
    .is-menu-open & {
        background-color: transparent;
		margin-left: auto;
		margin-right: 0;

		&__inner span {
			&:nth-child(1),
			&:nth-child(6) {
				@include rotate(45);
			}
			&:nth-child(2),
			&:nth-child(5) {
				@include rotate(-45);
			}
			&:nth-child(1) {
				left: 3px;
				top: 8px;
			}
			&:nth-child(2) {
				right: 3px;
				left: auto;
				top: 8px;
			}
			&:nth-child(3) {
				left: -50%;
				opacity: 0;
			}
			&:nth-child(4) {
				left: 100%;
				opacity: 0;
			}
			&:nth-child(5) {
				left: 3px;
				top: 15px;
			}
			&:nth-child(6) {
				right: 3px;
				left: auto;
				top: 15px;
			}
		}
	}
	
	&--small {
		width: 40px;
		height: 40px;
		
		.menu-trigger {
			&__inner {
				margin: 10px;
				position: relative;
				display: block;
				width: 20px;
				height: 20px;

				span {
					display: block;
					position: absolute;
					height: 3px;
					width: 50%;
					background: $key-color-1;
					opacity: 1;
					@include transition(all,.25s,ease-in-out);
					@include rotate(0);

					&:nth-child(even) {
						right: 0;
						border-radius: 0 3px 3px 0;
					}
					&:nth-child(odd) {
						left: 0;
						border-radius: 3px 0 0 3px;
					}
					&:nth-child(1),
					&:nth-child(2) {
						top: 2px;
					}
					&:nth-child(3),
					&:nth-child(4) {
						top: 9px;
					}
					&:nth-child(4) {
						right: auto;
						left: 50%;
					}
					&:nth-child(5),
					&:nth-child(6) {
						top: 16px;
					}
				}
			}

            .is-menu-open & {
                &__inner span {
					&:nth-child(1),
                    &:nth-child(6) {
                        @include rotate(45);
                    }
                    &:nth-child(2),
                    &:nth-child(5) {
                        @include rotate(-45);
                    }
                    &:nth-child(1) {
                        left: 2px;
                        top: 6px;
                    }
                    &:nth-child(2) {
                        right: 2px;
                        left: auto;
                        top: 6px;
                    }
                    &:nth-child(3) {
                        left: -50%;
                        opacity: 0;
                    }
                    &:nth-child(4) {
                        left: 100%;
                        opacity: 0;
                    }
                    &:nth-child(5) {
                        left: 2px;
                        top: 12px;
                    }
                    &:nth-child(6) {
                        right: 2px;
                        left: auto;
                        top: 12px;
                    }
                }
            } 
		}
	}
}
