// colors
$key-color-1: #364a59;
$key-color-2: #bcd3e5;
$key-color-3: #f1f5f8;
$key-color-4: #333333;
$key-color-5: #ed9c2a;
$key-color-6: #30536f;
$key-color-7: #93b8d5;
$key-color-8: #e6f0f7;

$default-font-color: $key-color-4;
$heading-color: #000;
$white-color: #ffffff;
$black-color: #000000;
$bg-color-body: $key-color-3;
$border-color: #ccc;

$color-success: #2ecc71;
$color-danger: #d75e51;
$color-warning: #ed9c2a;
$color-info: #3498db;
$color-dark: $key-color-1;
$color-pink: #e83e8c;

// shadow
$default-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.1);

// font
$default-font-family: 'Open Sans', sans-serif;
$default-font-weight-light: 300;
$default-font-weight: 400;
$default-font-weight-semibold: 600;
$default-font-weight-bold: 700;
$default-font-size: 15px;

$default-lead-font-size: 18px;
$default-lead-font-weight: $default-font-weight;

// icons
$project-font-family-icon: 'Icons';

// list
$list-li-font-family: $project-font-family-icon;
$list-li-icon-content: '\e020';
$list-li-icon-size: 13px;
$list-li-icon-color: $key-color-1;
$list-li-icon-top: 3px;
$list-li-icon-right: auto;
$list-li-icon-bottom: auto;
$list-li-icon-left: 0;

// line-height
$default-line-height: 1.35;
$small-line-height: $default-line-height;

// radius
$default-radius: 5px;

// margin/padding
$clear-padding: 0;
$clear-margin: 0;
$default-padding: 0 0 25px;
$default-margin: 0 0 25px;
$default-margin-bottom: 25px;
$list-li-padding: 0 0 0 20px;
$list-li-margin: 0 0 10px;
$list-sub-margin: 10px 0 0;

// links
$link-color: $key-color-1;
$link-color-hover: lighten($key-color-1, 15%);
$link-weight: $default-font-weight;

// ::selection
$selection-bg-color: lighten($key-color-1, 20%);
$selection-color: #fff;

//
// heading
//
$heading-weight: 400 !default;
$heading-line-height: 1.25;
$heading-margin: 0 0 25px !default;

// h1
$heading-1-size: 38px !default;
$heading-1-weight: $heading-weight;
$heading-1-margin: $heading-margin;
$heading-1-color: $heading-color;
$heading-1-line-height: $heading-line-height;

// h2
$heading-2-size: 34px !default;
$heading-2-weight: $heading-weight;
$heading-2-margin: $heading-margin;
$heading-2-color: $heading-color;
$heading-2-line-height: $heading-line-height;

// h3
$heading-3-size: 30px !default;
$heading-3-weight: $heading-weight;
$heading-3-margin: $heading-margin;
$heading-3-color: $heading-color;
$heading-3-line-height: $heading-line-height;

// h4
$heading-4-size: 26px !default;
$heading-4-weight: $heading-weight;
$heading-4-margin: $heading-margin;
$heading-4-color: $heading-color;
$heading-4-line-height: $heading-line-height;

// h5
$heading-5-size: 22px !default;
$heading-5-weight: $heading-weight;
$heading-5-margin: $heading-margin;
$heading-5-color: $heading-color;
$heading-5-line-height: $heading-line-height;

// h6
$heading-6-size: 18px !default;
$heading-6-weight: $heading-weight;
$heading-6-margin: $heading-margin;
$heading-6-color: $heading-color;
$heading-6-line-height: $heading-line-height;

//
// MEDIA QUERIES
//
$xxs-max: 575px;
$xs-min: 576px;
$xs-max: 767px;
$sm-min: 768px;
$sm-max: 991px;
$md-min: 992px;
$md-max: 1199px;
$lg-min: 1200px;
$lg-max: 1599px;
$xlg-min: 1600px;

//
// CONTAINERS
//
$xs-container-width: 540px;
$xs-container-wide-width: 540px;
$sm-container-width: 720px;
$sm-container-wide-width: 720px;
$md-container-width: 960px;
$md-container-wide-width: 960px;
$lg-container-width: 1200px;
$lg-container-wide-width: 1390px;
$xlg-container-width: 1390px;
$xlg-container-wide-width: 1570px;

$max-width-limiter: 1920px;
