$gallery-margin: $default-margin;

$gallery-title-margin: $default-margin;

$gallery-thumbnail-margin-bottom: 30px;

$gallery-thumbnail-background-color: $key-color-1;
$gallery-thumbnail-background-color-opacity: 0;
$gallery-thumbnail-background-color-opacity-hover: 1;

$gallery-thumbnail-icon-font-size: 45px;
$gallery-thumbnail-icon-font-size-small: 25px;
$gallery-thumbnail-icon-opacity-hover: 1;
$gallery-thumbnail-icon-zoom-out-scale: 2;
$gallery-thumbnail-icon-zoom-out-scale-hover: 1;

$gallery-thumbnail-img-opacity-hover: 0.2;
$gallery-thumbnail-img-zoom-scale-hover: 1.1;


.gallery {
	margin: $gallery-margin;
	
	&__title {

    }
    
	&__list {
        
    }
    
    &__item {

    }
    
    .gutters-10 {
        .thumbnail {
            margin-bottom: 10px;
        }
    }
    
    .gutters-20 {
        .thumbnail {
            margin-bottom: 20px;
        }
    }
}

.thumbnail {
	position: relative;
	display: block;
	width: 100%;
	overflow: hidden;
	background-color: rgba($gallery-thumbnail-background-color, $gallery-thumbnail-background-color-opacity);
	@include transition(background);
	margin-bottom: $gallery-thumbnail-margin-bottom;
	
	@include hover() {
        background: rgba($gallery-thumbnail-background-color, $gallery-thumbnail-background-color-opacity-hover);

		img {
        	opacity: $gallery-thumbnail-img-opacity-hover;
        }
        
        .ico,
		.ico-default {
        	opacity: $gallery-thumbnail-icon-opacity-hover;
        }
    }
	
	img {
		display: block;
		width: 100%;
		height: auto;
		transform: translateZ(0);
		@include transition();
	}

	.ico,
	.ico-default {
		font-size: $gallery-thumbnail-icon-font-size;
		color: #FFF;
		position: absolute;
		z-index: 2;
		left: 50%;
		top: 50%;
		@include translate(-50%, -50%);
		opacity: 0;
		@include transition();
		
		&--small {
			font-size: $gallery-thumbnail-icon-font-size-small;
		}
	}
	
	&--mb-clear {
		margin-bottom: 0 !important;
	}

	&--img-zoom-in {
		img {
			max-width: 100% !important;
			@include transition(all, 0.4s, ease);
			z-index: 1;
		}

		@include hover() {
	        img {
				@include scale($gallery-thumbnail-img-zoom-scale-hover)
	        }
	    }
	}

	&--icon-slide {
		.ico,
		.ico-default {
			top: 25%;
		}

		@include hover() {
	        .ico,
			.ico-default {
	        	top: 50%;
	        }
	    }
	}

	&--icon-zoom-out {
		.ico,
		.ico-default {
			transform-origin: center center;
			transform: translate(-50%, -50%) scale($gallery-thumbnail-icon-zoom-out-scale);
			@include transition($delay: 0s);
		}

		@include hover() {
	        .ico,
			.ico-default {
	        	transform: translate(-50%, -50%) scale($gallery-thumbnail-icon-zoom-out-scale-hover);
				@include transition($delay: 0.15s);
	        }
	    }
	}
}
