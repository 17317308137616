.stepper {
  margin-bottom: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include max-screen($xs-max) {
      margin-bottom: 30px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 157px;

    @include max-screen($xs-max) {
        width: 50px;
    }
  }

  &__divider {
    position: relative;
    top: 40px;
    left: 0;

    @include max-screen($xs-max) {
        display: none;
    }
  }

  &__step {
    position: relative;
    width: 100px;
    min-width: 100px;
    height: 100px;
    min-height: 100px;
    border-radius: 50vw;
    background-color: $white-color;
    border: 2px solid $key-color-2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $key-color-6;
    font-size: 27px;
    font-weight: $default-font-weight-bold;
    z-index: 0;
    margin-bottom: 17px;

    @include max-screen($xs-max) {
      width: 50px;
      min-width: 50px;
      height: 50px;
      min-height: 50px;
      font-size: 12px;
      color: $key-color-6;
    }

    &::before {
      position: absolute;
      content: '';
      top: 7px;
      left: 7px;
      width: calc(100% - 14px);
      min-width: calc(100% - 14px);
      height: calc(100% - 14px);
      min-height: calc(100% - 14px);
      border-radius: 50vw;
      background-color: #E6F0F7;
      z-index: -1;
      @include max-screen($xs-max) {
        width: calc(100% - 10px);
        min-width: calc(100% - 10px);
        height: calc(100% - 10px);
        min-height: calc(100% - 10px);
        top: 5px;
        left: 5px;
      }
    }

    .active & {
      border-color: $key-color-5;
      color: $white-color;

      &::before {
        background-color: $key-color-6;
      }
    }

    .completed & {
      color: $white-color;
      &::before {
        background-color: $key-color-6;
      }
    }
  }

  &__title {
    text-align: center;
    font-size: 16px;
    color: #333333;
    line-height: 1.2;

    @include max-screen($xs-max) {
        display: none;
    }

    .active & {
      color: $key-color-6;
      font-weight: $default-font-weight-bold;
      position: relative;
      &::before {
          position: absolute;
          content: '';
          bottom: -13px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $key-color-2;
      }
    }
  }
}
