.notifications {
  font-size: 13px;
  color: $black-color;
  line-height: 1.60;

  &__heading {
    font-size: 15px;
    text-transform: uppercase;
    color: $black-color;
    font-weight: $default-font-weight-bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 2px solid #BCD3E5;


    &-left {

    }

    &-right {

    }
  }


  &__content {
    padding: 15px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 2px solid #BCD3E5;

    @include max-screen($xs-max) {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &:last-child {
      border: none;
    }
  }

  &__text {
    font-size: 13px;
    color: $black-color;
    line-height: 1.60;

    &.notification {
      position: relative;
      padding-left: 20px;
      font-weight: $default-font-weight-bold;

      &::before {
        position: absolute;
        content: '';
        top: 5px;
        left: 0;
        width: 11px;
        min-width: 11px;
        height: 11px;
        min-height: 11px;
        background-color: $color-info;
        border-radius: 50vw;
      }

      &--success {
        &::before {
          background-color: $color-success;
        }
      }

      &--warning {
        &::before {
          background-color: $color-warning;
        }
      }

      &--alert {
        &::before {
          background-color: $color-danger;
        }
      }
    }
  }

  &__date {
    margin-left: 80px;
    font-size: 13px;
    color: $black-color;
    line-height: 1.60;

    @include max-screen($xs-max) {
      margin-left: auto;
      margin-bottom: 5px;
    }
  }
}

