.pagination {
  margin-top: 40px;
  margin-bottom: 20px;
  @extend .flex, .flex--center-y;
  justify-content: center;

  &__list {
    @extend .flex, .flex--center-y;
  }

  &__item {
    font-size: 15px;
    font-weight: $default-font-weight;
    color: #252525;
    margin: 0 3px;

    &--prev, &--next {
      font-weight: $default-font-weight-bold;
      margin: 0 6px;
    }
  }
}
