//
// custom gutters
//
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.gutters-10 {
    margin-right: -5px;
    margin-left: -5px;
}

.gutters-10 > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
}

.gutters-20 {
    margin-right: -10px;
    margin-left: -10px;
}

.gutters-20 > [class*='col-'] {
    padding-right: 10px;
    padding-left: 10px;
}