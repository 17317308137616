//
// .btn-group
//
$btn-group-margin: 0 -5px 30px;
$btn-group-btn-margin: 5px;


//
// .btn // default
//
$btn-margin: $clear-margin;

$btn-padding-top: 18px;
$btn-padding-right: 35px;
$btn-padding-bottom: 17px;
$btn-padding-left: 35px;

$btn-text-align: center;
$btn-text-transform: none;
$btn-line-height: 1.2;
$btn-letter-spacing: normal;

$btn-font-size: 14px;
$btn-font-family: $default-font-family;
$btn-font-weight: $default-font-weight-semibold;

$btn-color: #fff;
$btn-color-hover: #fff;

$btn-bg-color: $key-color-1;
$btn-bg-color-hover: $key-color-2;

$btn-border: 2px solid transparent;
$btn-border-hover: $btn-border;
$btn-border-radius: 0;


//
// .btn--primary
//
$btn-primary-color: $key-color-6;
$btn-primary-color-hover: $key-color-6;

$btn-primary-bg-color: $key-color-8;
$btn-primary-bg-color-hover: $key-color-8;

$btn-primary-border: 2px solid $key-color-7;
$btn-primary-border-hover: 2px solid $key-color-1;


//
// .btn--outline
//
$btn-outline-color: $key-color-1;
$btn-outline-color-hover: $key-color-1;

$btn-outline-bg-color: $white-color;
$btn-outline-bg-color-hover: $white-color;

$btn-outline-border: 2px solid $key-color-2;
$btn-outline-border-hover: 2px solid $key-color-1;

$btn-upload-border: 2px solid $key-color-7;


//
// .btn--small
//
$btn-small-padding-divider: 1.5;
$btn-small-font-size: $default-font-size - 2px;

	// don't edit | this code generate small size btn
	$btn-small-padding-top: $btn-padding-top / $btn-small-padding-divider;
	$btn-small-padding-right: $btn-padding-right / $btn-small-padding-divider;
	$btn-small-padding-bottom: $btn-padding-bottom / $btn-small-padding-divider;
	$btn-small-padding-left: $btn-padding-left / $btn-small-padding-divider;


//
// .btn--large
//
$btn-large-padding-divider: 1.5;
$btn-large-font-size: $default-font-size + 2px;

	// don't edit | this code generate large size btn
	$btn-large-padding-top: $btn-padding-top * $btn-large-padding-divider;
	$btn-large-padding-right: $btn-padding-right * $btn-large-padding-divider;
	$btn-large-padding-bottom: $btn-padding-bottom * $btn-large-padding-divider;
	$btn-large-padding-left: $btn-padding-left * $btn-large-padding-divider;


//---[end vars]-----------------------------------------//


// btn group
.btn-group {
	@extend .flex;
	margin: $btn-group-margin;
	
    > .btn {
		margin: $btn-group-btn-margin;
	}
}

// default btn
.btn {
	@extend .flex, .flex--inline, .flex--nowrap;
	cursor: pointer;
	padding: {
		top: $btn-padding-top;
		right: $btn-padding-right;
		bottom: $btn-padding-bottom;
		left: $btn-padding-left;
	}
	margin: $btn-margin;
	text-align: $btn-text-align;
	text-decoration: none;
	text-transform: $btn-text-transform;
	line-height: $btn-line-height;
	letter-spacing: $btn-letter-spacing;
	color: $btn-color;
	background-color: $btn-bg-color;
	border: $btn-border;
	border-radius: $btn-border-radius;
	font-size: $btn-font-size;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	
	@include transition();

	i {
		position: relative;
		top: 2px;
		margin-right: 13px;
	}

	@include hover() {
        border: $btn-border-hover;
		text-decoration: none;
		background-color: $btn-bg-color-hover;
		color: $btn-color-hover;
    }

	// primary btn
    &--primary {
    	color: $btn-primary-color;
    	background-color: $btn-primary-bg-color;
    	border: $btn-primary-border;

    	@include hover() {
    		color: $btn-primary-color-hover;
    		background-color: $btn-primary-bg-color-hover;
    		border: $btn-primary-border-hover;
    	}
    }

	&--danger {
		color: #fff;
		background-color: $color-danger;
		border: $color-danger;

		@include hover() {
			color: #fff;
			background-color: #c33b2c;
			border: #d75e51;
		}
	}
    
    // outline btn
    &--outline {
    	color: $btn-outline-color;
    	background-color: $btn-outline-bg-color;
    	border: $btn-outline-border;

    	@include hover() {
    		color: $btn-outline-color-hover;
    		background-color: $btn-outline-bg-color-hover;
    		border: $btn-outline-border-hover;
    	}
    }
	// outline no background
	&--outline-nb {
		color: $btn-outline-color;
		background-color: transparent;
		border: $btn-outline-border;
		white-space: normal;

		@include hover() {
			color: $btn-outline-color-hover;
			background-color: $btn-outline-bg-color-hover;
			border: $btn-outline-border-hover;
		}
	}

	&--min {
		min-width: 255px;
		justify-content: center;

		@include max-screen($xxs-max) {
		    min-width: initial;
		}
	}

	&--upload {
		color: $black-color;
		background-color: $key-color-8;
		border: $btn-upload-border;
		font-weight: $default-font-weight-semibold;

		i {
			margin-right: 8px;
		}

		@include hover() {
			color: $black-color;
			background-color: $key-color-8;
			border: $btn-outline-border-hover;
		}
	}

	&--sort {
		background: none;
		color: $black-color;
		font-size: 15px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-transform: uppercase;

		@include hover() {
			background: none;
			color: $black-color;
		}

		i {
			position: relative;
			color: $key-color-5;
			margin-left: 8px;
			@include transition();
		}

		&.desc i {
			transform: rotate(0);
		}


		&.asc i {
			transform: rotate(180deg);
		}
	}
    
    // small btn
    &--small {
    	padding: {
			top: $btn-small-padding-top;
			right: $btn-small-padding-right;
			bottom: $btn-small-padding-bottom;
			left: $btn-small-padding-left;
		}
		font-size: $btn-small-font-size;
    }
    
    // large btn
    &--large {
    	padding: {
			top: $btn-large-padding-top;
			right: $btn-large-padding-right;
			bottom: $btn-large-padding-bottom;
			left: $btn-large-padding-left;
		}
		font-size: $btn-large-font-size;
    }
    
	// block btn
	&--block {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		min-width: 0;
	}

	&--short {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: {
			top: 17px;
			right: 18px;
			bottom: 18px;
			left: 18px;
		}
		i {
			position: relative;
			top: 0;
			margin-right: 12px;
		}
	}

	&--close {
		display: flex;
		align-items: center;
		justify-content: center !important;
		width: 59px;
		height: 55px;
		padding: 0;

		i {
			font-size: 16px;
			margin: 0 auto;
		}
	}


	&--plain {
		padding: 0;
		margin: 0;
		background: none;
		color: $key-color-1;
		font-size: 14px;
		text-transform: uppercase;
		font-weight: $default-font-weight-bold;

		@include hover() {
			background: none;
			color: $key-color-5;
		}
	}



	// outline btn
	&--outline-gradient {
		padding: {
			top: $btn-padding-top  + 3px;
			right: $btn-padding-right;
			bottom: $btn-padding-bottom  + 3px;
			left: $btn-padding-left;
		}
		background: none;
		border: none;
		border-radius: 0;
		font-weight: $default-font-weight-bold;
		font-size: 13px;
		color: $key-color-1 !important;
		text-decoration: none !important;
		cursor: pointer;
		outline: 0;
		-webkit-tap-highlight-color: transparent;
		text-align: center;
		border-left: 3px solid $key-color-1;
		border-right: 3px solid $key-color-2;
		display: inline-block;
		background-position: 0 100%, 0 0;
		background-repeat: no-repeat;
		background-size: 200% 3px;
		background-image: -webkit-linear-gradient(left, $key-color-1 0%, $key-color-2 50%, $key-color-1 100%), -webkit-linear-gradient(left, $key-color-1 0%, $key-color-2 50%, $key-color-1 100%);
		background-image: linear-gradient(to right, $key-color-1 0%, $key-color-2 50%, $key-color-1 100%), linear-gradient(to right, $key-color-1 0%, $key-color-2 50%, $key-color-1 100%);
		transition: background-position 0.3s ease-in-out, border-color 0.3s ease-in-out;
		-moz-transition: background-position 0.3s ease-in-out, border-color 0.3s ease-in-out;
		-ms-transition: background-position 0.3s ease-in-out, border-color 0.3s ease-in-out;
		-o-transition: background-position 0.3s ease-in-out, border-color 0.3s ease-in-out;
		-webkit-transition: background-position 0.3s ease-in-out, border-color 0.3s ease-in-out;
		//animation: gradient-ghost-btn-animation 3s ease infinite;

		@include hover() {
			//color: $btn-outline-color-hover;
			background-color: transparent;
			border: none;

			background-position: 100% 100%, 100% 0;
			border-left: 3px solid $key-color-2;
			border-right: 3px solid $key-color-1;
		}
	}

	&--gradient {
		background-size: 200% 100%;
		background-position: 10%;
		color: #fff;
		background-image: -webkit-gradient(linear,left top,right top,from($key-color-2),color-stop(51%,$key-color-1),to($key-color-1));
		background-image: linear-gradient(90deg,$key-color-2 0,$key-color-1 51%,$key-color-1);
		-webkit-transition: background-position .5s ease;
		transition: background-position .5s ease;

		@include hover() {
			color: #fff;
			background-position: 90%;
			border: 2px solid transparent;
			text-decoration: none;
			background-color: #7c21ec;
		}
	}

}
