/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */


/*
 *  Owl Carousel - Core
 */

 .owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
	touch-action: manipulation;
	-moz-backface-visibility: hidden;
	/* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
	display: block;
	width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
	display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
	background: none;
	color: inherit;
	border: none;
	//padding: 0 !important;
	font: inherit;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
	visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-grab {
	cursor: move;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}


/* No Js */

.no-js .owl-carousel {
	display: block;
}


/*
 *  Owl Carousel - Animate Plugin
 */

.owl-carousel .animated {
	animation-duration: 1000ms;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	animation-name: fadeOut;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}


/*
 * 	Owl Carousel - Auto Height Plugin
 */

.owl-height {
	transition: height 500ms ease-in-out;
}


/*
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel .owl-item {
	/**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */
}

.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
	max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
	transform-style: preserve-3d;
}


/*
 * 	Owl Carousel - Video Plugin
 */

.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzAuMDUxIDMwLjA1MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzAuMDUxIDMwLjA1MTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGQ9Ik0xOS45ODIsMTQuNDM4bC02LjI0LTQuNTM2Yy0wLjIyOS0wLjE2Ni0wLjUzMy0wLjE5MS0wLjc4NC0wLjA2MmMtMC4yNTMsMC4xMjgtMC40MTEsMC4zODgtMC40MTEsMC42Njl2OS4wNjljMCwwLjI4NCwwLjE1OCwwLjU0MywwLjQxMSwwLjY3MWMwLjEwNywwLjA1NCwwLjIyNCwwLjA4MSwwLjM0MiwwLjA4MWMwLjE1NCwwLDAuMzEtMC4wNDksMC40NDItMC4xNDZsNi4yNC00LjUzMmMwLjE5Ny0wLjE0NSwwLjMxMi0wLjM2OSwwLjMxMi0wLjYwN0MyMC4yOTUsMTQuODAzLDIwLjE3NywxNC41OCwxOS45ODIsMTQuNDM4eiIvPjxwYXRoIGQ9Ik0xNS4wMjYsMC4wMDJDNi43MjYsMC4wMDIsMCw2LjcyOCwwLDE1LjAyOGMwLDguMjk3LDYuNzI2LDE1LjAyMSwxNS4wMjYsMTUuMDIxYzguMjk4LDAsMTUuMDI1LTYuNzI1LDE1LjAyNS0xNS4wMjFDMzAuMDUyLDYuNzI4LDIzLjMyNCwwLjAwMiwxNS4wMjYsMC4wMDJ6IE0xNS4wMjYsMjcuNTQyYy02LjkxMiwwLTEyLjUxNi01LjYwMS0xMi41MTYtMTIuNTE0YzAtNi45MSw1LjYwNC0xMi41MTgsMTIuNTE2LTEyLjUxOGM2LjkxMSwwLDEyLjUxNCw1LjYwNywxMi41MTQsMTIuNTE4QzI3LjU0MSwyMS45NDEsMjEuOTM3LDI3LjU0MiwxNS4wMjYsMjcuNTQyeiIvPjwvZz48L3N2Zz4=);
	background-size: 80px 80px;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
	-ms-transform: scale(1.3, 1.3);
	transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%;
}








.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.animated.infinite {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.animated.hinge {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
}

@-webkit-keyframes slideOutUp {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(-2000px);
		transform: translateY(-2000px);
	}
}

@keyframes slideOutUp {
	0% {
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(-2000px);
		-ms-transform: translateY(-2000px);
		transform: translateY(-2000px);
	}
}

.slideOutUp {
	-webkit-animation-name: slideOutUp;
	animation-name: slideOutUp;
}

@-webkit-keyframes slideInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(2000px);
		transform: translateY(2000px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes slideInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(2000px);
		-ms-transform: translateY(2000px);
		transform: translateY(2000px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
}

.slideInUp {
	-webkit-animation-name: slideInUp;
	animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(2000px);
		transform: translateY(2000px);
	}
}

@keyframes slideOutDown {
	0% {
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(2000px);
		-ms-transform: translateY(2000px);
		transform: translateY(2000px);
	}
}

.slideOutDown {
	-webkit-animation-name: slideOutDown;
	animation-name: slideOutDown;
}

@-webkit-keyframes hinge {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
		-webkit-transform-origin: top left;
		transform-origin: top left;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
	}

	20%, 60% {
		-webkit-transform: rotate(80deg);
		transform: rotate(80deg);
		-webkit-transform-origin: top left;
		transform-origin: top left;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
	}

	40% {
		-webkit-transform: rotate(60deg);
		transform: rotate(60deg);
		-webkit-transform-origin: top left;
		transform-origin: top left;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
	}

	80% {
		-webkit-transform: rotate(60deg) translateY(0);
		transform: rotate(60deg) translateY(0);
		-webkit-transform-origin: top left;
		transform-origin: top left;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
		opacity: 1;
	}

	100% {
		-webkit-transform: translateY(700px);
		transform: translateY(700px);
		opacity: 0;
	}
}

@-webkit-keyframes flip {
	0% {
		-webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
		transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	40% {
		-webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
		transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	50% {
		-webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
		transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	80% {
		-webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
		transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	100% {
		-webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
		transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
}

@keyframes flip {
	0% {
		-webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
		-ms-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
		transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	40% {
		-webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
		-ms-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
		transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	50% {
		-webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
		-ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
		transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	80% {
		-webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
		-ms-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
		transform: perspective(400px) translateZ(0) rotateY(360deg) scale(.95);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	100% {
		-webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
		-ms-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
		transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
}

.animated.flip {
	-webkit-backface-visibility: visible;
	-ms-backface-visibility: visible;
	backface-visibility: visible;
	-webkit-animation-name: flip;
	animation-name: flip;
}

@-webkit-keyframes flipInX {
	0% {
		-webkit-transform: perspective(400px) rotateX(90deg);
		transform: perspective(400px) rotateX(90deg);
		opacity: 0;
	}

	40% {
		-webkit-transform: perspective(400px) rotateX(-10deg);
		transform: perspective(400px) rotateX(-10deg);
	}

	70% {
		-webkit-transform: perspective(400px) rotateX(10deg);
		transform: perspective(400px) rotateX(10deg);
	}

	100% {
		-webkit-transform: perspective(400px) rotateX(0deg);
		transform: perspective(400px) rotateX(0deg);
		opacity: 1;
	}
}

@keyframes flipInX {
	0% {
		-webkit-transform: perspective(400px) rotateX(90deg);
		-ms-transform: perspective(400px) rotateX(90deg);
		transform: perspective(400px) rotateX(90deg);
		opacity: 0;
	}

	40% {
		-webkit-transform: perspective(400px) rotateX(-10deg);
		-ms-transform: perspective(400px) rotateX(-10deg);
		transform: perspective(400px) rotateX(-10deg);
	}

	70% {
		-webkit-transform: perspective(400px) rotateX(10deg);
		-ms-transform: perspective(400px) rotateX(10deg);
		transform: perspective(400px) rotateX(10deg);
	}

	100% {
		-webkit-transform: perspective(400px) rotateX(0deg);
		-ms-transform: perspective(400px) rotateX(0deg);
		transform: perspective(400px) rotateX(0deg);
		opacity: 1;
	}
}

.flipInX {
	-webkit-backface-visibility: visible !important;
	-ms-backface-visibility: visible !important;
	backface-visibility: visible !important;
	-webkit-animation-name: flipInX;
	animation-name: flipInX;
}

@-webkit-keyframes flipInY {
	0% {
		-webkit-transform: perspective(400px) rotateY(90deg);
		transform: perspective(400px) rotateY(90deg);
		opacity: 0;
	}

	40% {
		-webkit-transform: perspective(400px) rotateY(-10deg);
		transform: perspective(400px) rotateY(-10deg);
	}

	70% {
		-webkit-transform: perspective(400px) rotateY(10deg);
		transform: perspective(400px) rotateY(10deg);
	}

	100% {
		-webkit-transform: perspective(400px) rotateY(0deg);
		transform: perspective(400px) rotateY(0deg);
		opacity: 1;
	}
}
