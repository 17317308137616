.custom-radios {


  &__wrapper {
    //display: flex;
    //align-items: center;
    //justify-content: space-between;
    margin-bottom: 30px;
  }

  &__item {
    width: 100%;
    border: 2px solid $key-color-7;
    background-color: $key-color-8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    cursor: pointer;
    margin-bottom: 15px;

    @include max-screen($xs-max) {
        padding: 20px 15px;
    }

    &:first-child {
      margin-right: 15px;

      @include max-screen($sm-max) {
          margin-right: 0;
      }
    }

    &:last-child {
      margin-left: 15px;

      @include max-screen($sm-max) {
        margin-left: 0;
      }
    }

    label.checkbox {
      @extend %disabled;
    }

    .checkbox__text {
      padding-left: 50px;

      &::after {
        top: 7px;
        left: 0;
        width: 38px;
        min-width: 38px;
        height: 38px;
        min-height: 38px;
        background-color: $white-color !important;
      }

      &::before {
        color: $key-color-5;
        //border-color: transparent $key-color-5 $key-color-5 transparent;
        top: 12px;
        left: 8px;
        width: 10px;
        height: 18px;
        border-top: 3px solid transparent;
        border-left: 3px solid transparent;
        border-right: 3px solid $key-color-5;
        border-bottom: 3px solid $key-color-5;
        border-radius: 0;
      }
    }

    .checkbox__label {
      font-size: 18px;
      font-weight: $default-font-weight-bold;
      color: $default-font-color;
      white-space: normal;
    }
  }

  &__info {
    font-size: 11px;
    color: $black-color;

    @include max-screen($sm-max) {
        margin-bottom: 20px;
    }
  }

  &__view {
    .checkbox__text {
      &::after {
        top: -5px;
      }

      &::before {
        top: 0px;
      }
    }
  }
}