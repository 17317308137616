.uploaded-materials-table-wrapper {
	//overflow-x: auto;
	min-height: 0.01%;

	@include max-screen($lg-max) {
		overflow-x: auto;
	}

	@include max-screen($xs-max) {
		width: 100%;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
}

.uploaded-materials-table {
	width: 100%;
	max-width: 100%;
	$this: &;

	@include max-screen($md-max) {
		display: block;
	}

	thead {
		font-size: 13px;
		color: $black-color;
		font-weight: $default-font-weight-semibold;
		text-transform: uppercase;

		@include max-screen($md-max) {
			display: none;
		}
	}

	tbody {
		@include max-screen($md-max) {
			display: flex;
			justify-content: flex-start;
			align-items: stretch;
			flex-flow: row wrap;
			overflow: hidden;
		}

		tr {
			@include transition(background-color);

			@include hover() {
				background-color: lighten($bg-color-body, 1.5%);
			}

			@include max-screen($md-max) {
				height: inherit;
				display: block;
				border: 1px solid #e7e5e1;
				margin: 5px;
				width: calc(50% - 10px);
				padding: 30px;

				td {
					display: block;
					border: 0 none;
					padding: 0;
					text-align: center;
					max-width: 100%;
					white-space: normal;

					+ td {
						margin-top: 15px;
					}
				}
			}

			@include max-screen($xs-max) {
				width: 100%;
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	.clickable-row {
		cursor: pointer;

		@include hover() {
			.investment-table__download {
				color: $key-color-1;
				@include transition();
			}
		}
	}

	&__th,
	&__td {
		border: 0 none;
		border-bottom: 1px #e7e5e1 solid;
		background-color: transparent;
		padding: 17px 10px;
		vertical-align: middle;
		letter-spacing: 0.05em;
		color: $key-color-4;
		font-weight: $default-font-weight-bold;
		font-size: 12px;
		//font-weight: 400;
	}

	&__th {
		color: $default-font-color;
		text-transform: uppercase;
		//white-space: nowrap;
	}

	&__td {
		font-size: 14px;
		color: $black-color;
		font-weight: normal;
	}

	.td-picture {
		padding-left: 0;

		.img {
			height: 67px;
			max-height: 67px;
			width: 93px;
			max-width: 93px;
			object-fit: scale-down;

			@include max-screen($md-max) {
				margin: 0 auto;
				width: 100%;
				max-width: 100%;
				height: auto;
				max-height: initial;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		@include max-screen($md-max) {
			text-align: center;
		}
	}

	.td-button {
		a {
			font-size: 14px;
			font-weight: $default-font-weight-bold;
			color: $key-color-6;
			@include transition();
			@include hover() {
				color: $key-color-5;
			}
		}
	}

	.td-info {
		white-space: nowrap;
	}

	.td-status {
	}

	.td-actions {
		.table-action-button {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 8px;
			font-size: 14px;
			font-weight: $default-font-weight-bold;
			color: $key-color-6;
			@include transition();
			@include hover() {
				color: $key-color-5;
			}

			i {
				margin-right: 8px;
				width: 13px;
			}

			@include max-screen($md-max) {
				justify-content: center;
			}
		}
	}

	&__rwd-label {
		display: none;
		color: $key-color-5;
		font-weight: $default-font-weight;
		text-transform: uppercase;
		margin-bottom: 3px;
		font-size: 10px;

		@include max-screen($md-max) {
			display: block;
			text-align: center;
			width: 100%;
		}
	}
}

.status {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 7px;
	&__dot {
		width: 11px;
		min-width: 11px;
		height: 11px;
		min-height: 11px;
		border-radius: 50vw;
		background-color: #c0c0c0;
	}

	&--warning {
		> .status__dot {
			background-color: $color-warning;
		}
	}
	&--danger {
		> .status__dot {
			background-color: $color-danger;
		}
	}
	&--success {
		> .status__dot {
			background-color: $color-success;
		}
	}
	&--info {
		> .status__dot {
			background-color: $color-info;
		}
	}
	&--dark {
		> .status__dot {
			background-color: $color-dark;
		}
	}
	&--pink {
		> .status__dot {
			background-color: $color-pink;
		}
	}

	&__label {
		font-size: 14px;
		font-weight: normal;
		color: $black-color;
	}
}
