%inner-padding {
    padding: 40px;
}

%clearfix {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

%disabled-scroll {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;
}

%disabled {
	pointer-events: none;
	-webkit-touch-callout: none;
	user-select: none;
}

%h {
    font-weight: $heading-weight;
    color: $heading-color;
    line-height: $heading-line-height;
    margin: $heading-margin;
}
