.container,
.container-fluid,
.container-wide {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;

    @include screen(360px, $xxs-max) {
        padding-right: 30px;
        padding-left: 30px;
    }
}

.row {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.row.reverse {
    -ms-flex-direction: row-reverse;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
}

.col.reverse {
    -ms-flex-direction: column-reverse;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
}

[class^="col-"] {
    width: 100%;
    padding: 0 15px;
}

.col-xxs,
.col-xxs-1,
.col-xxs-2,
.col-xxs-2-3,
.col-xxs-3,
.col-xxs-4,
.col-xxs-5,
.col-xxs-6,
.col-xxs-7,
.col-xxs-8,
.col-xxs-9,
.col-xxs-10,
.col-xxs-11,
.col-xxs-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xxs {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
}

.col-xxs-auto {
    width: auto;
    max-width: none;
}

.col-xxs-1 {
    -ms-flex-preferred-size: 8.333333%;
    flex-basis: 8.333333%;
    max-width: 8.333333%;
}

.col-xxs-2 {
    -ms-flex-preferred-size: 16.666666%;
    flex-basis: 16.666666%;
    max-width: 16.666666%;
}

.col-xxs-2-3 {
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
    max-width: 20%;
}

.col-xxs-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
}

.col-xxs-4 {
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    max-width: 33.333333%;
}

.col-xxs-5 {
    -ms-flex-preferred-size: 41.666666%;
    flex-basis: 41.666666%;
    max-width: 41.666666%;
}

.col-xxs-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
}

.col-xxs-7 {
    -ms-flex-preferred-size: 58.333333%;
    flex-basis: 58.333333%;
    max-width: 58.333333%;
}

.col-xxs-8 {
    -ms-flex-preferred-size: 66.666666%;
    flex-basis: 66.666666%;
    max-width: 66.666666%;
}

.col-xxs-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
}

.col-xxs-10 {
    -ms-flex-preferred-size: 83.333333%;
    flex-basis: 83.333333%;
    max-width: 83.333333%;
}

.col-xxs-11 {
    -ms-flex-preferred-size: 91.666666%;
    flex-basis: 91.666666%;
    max-width: 91.666666%;
}

.col-xxs-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
}

.col-xxs-offset-0 {
    margin-left: 0;
}

.col-xxs-offset-1 {
    margin-left: 8.333333%;
}

.col-xxs-offset-2 {
    margin-left: 16.666666%;
}

.col-xxs-offset-2-3 {
    margin-left: 20%;
}

.col-xxs-offset-3 {
    margin-left: 25%;
}

.col-xxs-offset-4 {
    margin-left: 33.333333%;
}

.col-xxs-offset-5 {
    margin-left: 41.666666%;
}

.col-xxs-offset-6 {
    margin-left: 50%;
}

.col-xxs-offset-7 {
    margin-left: 58.333333%;
}

.col-xxs-offset-8 {
    margin-left: 66.666666%;
}

.col-xxs-offset-9 {
    margin-left: 75%;
}

.col-xxs-offset-10 {
    margin-left: 83.333333%;
}

.col-xxs-offset-11 {
    margin-left: 91.666666%;
}

.start-xxs {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
}

.center-xxs {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
}

.end-xxs {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
}

.top-xxs {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
}

.middle-xxs {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
}

.bottom-xxs {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
}

.around-xxs {
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.between-xxs {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.first-xxs {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
}

.last-xxs {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
}

@media only screen and (min-width: $xs-min) {
    .container {
        max-width: $xs-container-width;
    }

    .container-wide {
        max-width: $xs-container-wide-width;
    }

    .col-xs,
    .col-xs-1,
    .col-xs-2,
    .col-xs-2-3,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12 {
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col-xs {
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-xs-auto {
        width: auto;
        max-width: none;
    }

    .col-xs-1 {
        -ms-flex-preferred-size: 8.333333%;
        flex-basis: 8.333333%;
        max-width: 8.333333%;
    }

    .col-xs-2 {
        -ms-flex-preferred-size: 16.666666%;
        flex-basis: 16.666666%;
        max-width: 16.666666%;
    }

    .col-xs-2-3 {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }

    .col-xs-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-xs-4 {
        -ms-flex-preferred-size: 33.333333%;
        flex-basis: 33.333333%;
        max-width: 33.333333%;
    }

    .col-xs-5 {
        -ms-flex-preferred-size: 41.666666%;
        flex-basis: 41.666666%;
        max-width: 41.666666%;
    }

    .col-xs-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-xs-7 {
        -ms-flex-preferred-size: 58.333333%;
        flex-basis: 58.333333%;
        max-width: 58.333333%;
    }

    .col-xs-8 {
        -ms-flex-preferred-size: 66.666666%;
        flex-basis: 66.666666%;
        max-width: 66.666666%;
    }

    .col-xs-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-xs-10 {
        -ms-flex-preferred-size: 83.333333%;
        flex-basis: 83.333333%;
        max-width: 83.333333%;
    }

    .col-xs-11 {
        -ms-flex-preferred-size: 91.666666%;
        flex-basis: 91.666666%;
        max-width: 91.666666%;
    }

    .col-xs-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-xs-offset-0 {
        margin-left: 0;
    }

    .col-xs-offset-1 {
        margin-left: 8.333333%;
    }

    .col-xs-offset-2 {
        margin-left: 16.666666%;
    }

    .col-xs-offset-2-3 {
        margin-left: 20%;
    }

    .col-xs-offset-3 {
        margin-left: 25%;
    }

    .col-xs-offset-4 {
        margin-left: 33.333333%;
    }

    .col-xs-offset-5 {
        margin-left: 41.666666%;
    }

    .col-xs-offset-6 {
        margin-left: 50%;
    }

    .col-xs-offset-7 {
        margin-left: 58.333333%;
    }

    .col-xs-offset-8 {
        margin-left: 66.666666%;
    }

    .col-xs-offset-9 {
        margin-left: 75%;
    }

    .col-xs-offset-10 {
        margin-left: 83.333333%;
    }

    .col-xs-offset-11 {
        margin-left: 91.666666%;
    }

    .start-xs {
        -ms-flex-pack: start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }

    .center-xs {
        -ms-flex-pack: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }

    .end-xs {
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }

    .top-xs {
        -ms-flex-align: start;
        -webkit-box-align: start;
        align-items: flex-start;
    }

    .middle-xs {
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;
    }

    .bottom-xs {
        -ms-flex-align: end;
        -webkit-box-align: end;
        align-items: flex-end;
    }

    .around-xs {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    .between-xs {
        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }

    .first-xs {
        -ms-flex-order: -1;
        -webkit-box-ordinal-group: 0;
        order: -1;
    }

    .last-xs {
        -ms-flex-order: 1;
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
}

@media only screen and (min-width: $sm-min) {
    .container {
        max-width: $sm-container-width;
    }

    .container-wide {
        max-width: $sm-container-wide-width;
    }

    .col-sm,
    .col-sm-1,
    .col-sm-2,
    .col-sm-2-3,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col-sm {
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-sm-auto {
        width: auto;
        max-width: none;
    }

    .col-sm-1 {
        -ms-flex-preferred-size: 8.333333%;
        flex-basis: 8.333333%;
        max-width: 8.333333%;
    }

    .col-sm-2 {
        -ms-flex-preferred-size: 16.666666%;
        flex-basis: 16.666666%;
        max-width: 16.666666%;
    }

    .col-sm-2-3 {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }

    .col-sm-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        -ms-flex-preferred-size: 33.333333%;
        flex-basis: 33.333333%;
        max-width: 33.333333%;
    }

    .col-sm-5 {
        -ms-flex-preferred-size: 41.666666%;
        flex-basis: 41.666666%;
        max-width: 41.666666%;
    }

    .col-sm-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        -ms-flex-preferred-size: 58.333333%;
        flex-basis: 58.333333%;
        max-width: 58.333333%;
    }

    .col-sm-8 {
        -ms-flex-preferred-size: 66.666666%;
        flex-basis: 66.666666%;
        max-width: 66.666666%;
    }

    .col-sm-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        -ms-flex-preferred-size: 83.333333%;
        flex-basis: 83.333333%;
        max-width: 83.333333%;
    }

    .col-sm-11 {
        -ms-flex-preferred-size: 91.666666%;
        flex-basis: 91.666666%;
        max-width: 91.666666%;
    }

    .col-sm-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-sm-offset-0 {
        margin-left: 0;
    }

    .col-sm-offset-1 {
        margin-left: 8.333333%;
    }

    .col-sm-offset-2 {
        margin-left: 16.666666%;
    }

    .col-sm-offset-2-3 {
        margin-left: 20%;
    }

    .col-sm-offset-3 {
        margin-left: 25%;
    }

    .col-sm-offset-4 {
        margin-left: 33.333333%;
    }

    .col-sm-offset-5 {
        margin-left: 41.666666%;
    }

    .col-sm-offset-6 {
        margin-left: 50%;
    }

    .col-sm-offset-7 {
        margin-left: 58.333333%;
    }

    .col-sm-offset-8 {
        margin-left: 66.666666%;
    }

    .col-sm-offset-9 {
        margin-left: 75%;
    }

    .col-sm-offset-10 {
        margin-left: 83.333333%;
    }

    .col-sm-offset-11 {
        margin-left: 91.666666%;
    }

    .start-sm {
        -ms-flex-pack: start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }

    .center-sm {
        -ms-flex-pack: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }

    .end-sm {
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }

    .top-sm {
        -ms-flex-align: start;
        -webkit-box-align: start;
        align-items: flex-start;
    }

    .middle-sm {
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;
    }

    .bottom-sm {
        -ms-flex-align: end;
        -webkit-box-align: end;
        align-items: flex-end;
    }

    .around-sm {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    .between-sm {
        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }

    .first-sm {
        -ms-flex-order: -1;
        -webkit-box-ordinal-group: 0;
        order: -1;
    }

    .last-sm {
        -ms-flex-order: 1;
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
}

@media only screen and (min-width: $md-min) {
    .container {
        max-width: $md-container-width;
    }

    .container-wide {
        max-width: $md-container-wide-width;
    }

    .col-md,
    .col-md-1,
    .col-md-2,
    .col-md-2-3,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col-md {
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-md-auto {
        width: auto;
        max-width: none;
    }

    .col-md-1 {
        -ms-flex-preferred-size: 8.333333%;
        flex-basis: 8.333333%;
        max-width: 8.333333%;
    }

    .col-md-2 {
        -ms-flex-preferred-size: 16.666666%;
        flex-basis: 16.666666%;
        max-width: 16.666666%;
    }

    .col-md-2-3 {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }

    .col-md-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-md-4 {
        -ms-flex-preferred-size: 33.333333%;
        flex-basis: 33.333333%;
        max-width: 33.333333%;
    }

    .col-md-5 {
        -ms-flex-preferred-size: 41.666666%;
        flex-basis: 41.666666%;
        max-width: 41.666666%;
    }

    .col-md-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-md-7 {
        -ms-flex-preferred-size: 58.333333%;
        flex-basis: 58.333333%;
        max-width: 58.333333%;
    }

    .col-md-8 {
        -ms-flex-preferred-size: 66.666666%;
        flex-basis: 66.666666%;
        max-width: 66.666666%;
    }

    .col-md-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-md-10 {
        -ms-flex-preferred-size: 83.333333%;
        flex-basis: 83.333333%;
        max-width: 83.333333%;
    }

    .col-md-11 {
        -ms-flex-preferred-size: 91.666666%;
        flex-basis: 91.666666%;
        max-width: 91.666666%;
    }

    .col-md-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-md-offset-0 {
        margin-left: 0;
    }

    .col-md-offset-1 {
        margin-left: 8.333333%;
    }

    .col-md-offset-2 {
        margin-left: 16.666666%;
    }

    .col-md-offset-2-3 {
        margin-left: 20%;
    }

    .col-md-offset-3 {
        margin-left: 25%;
    }

    .col-md-offset-4 {
        margin-left: 33.333333%;
    }

    .col-md-offset-5 {
        margin-left: 41.666666%;
    }

    .col-md-offset-6 {
        margin-left: 50%;
    }

    .col-md-offset-7 {
        margin-left: 58.333333%;
    }

    .col-md-offset-8 {
        margin-left: 66.666666%;
    }

    .col-md-offset-9 {
        margin-left: 75%;
    }

    .col-md-offset-10 {
        margin-left: 83.333333%;
    }

    .col-md-offset-11 {
        margin-left: 91.666666%;
    }

    .start-md {
        -ms-flex-pack: start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }

    .center-md {
        -ms-flex-pack: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }

    .end-md {
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }

    .top-md {
        -ms-flex-align: start;
        -webkit-box-align: start;
        align-items: flex-start;
    }

    .middle-md {
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;
    }

    .bottom-md {
        -ms-flex-align: end;
        -webkit-box-align: end;
        align-items: flex-end;
    }

    .around-md {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    .between-md {
        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }

    .first-md {
        -ms-flex-order: -1;
        -webkit-box-ordinal-group: 0;
        order: -1;
    }

    .last-md {
        -ms-flex-order: 1;
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
}

@media only screen and (min-width: $lg-min) {
    .container {
        max-width: $lg-container-width;
    }

    .container-wide {
        max-width: $lg-container-wide-width;
    }

    .col-lg,
    .col-lg-1,
    .col-lg-2,
    .col-lg-2-3,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12 {
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col-lg {
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-lg-auto {
        width: auto;
        max-width: none;
    }

    .col-lg-1 {
        -ms-flex-preferred-size: 8.333333%;
        flex-basis: 8.333333%;
        max-width: 8.333333%;
    }

    .col-lg-2 {
        -ms-flex-preferred-size: 16.666666%;
        flex-basis: 16.666666%;
        max-width: 16.666666%;
    }

    .col-lg-2-3 {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }

    .col-lg-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        -ms-flex-preferred-size: 33.333333%;
        flex-basis: 33.333333%;
        max-width: 33.333333%;
    }

    .col-lg-5 {
        -ms-flex-preferred-size: 41.666666%;
        flex-basis: 41.666666%;
        max-width: 41.666666%;
    }

    .col-lg-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        -ms-flex-preferred-size: 58.333333%;
        flex-basis: 58.333333%;
        max-width: 58.333333%;
    }

    .col-lg-8 {
        -ms-flex-preferred-size: 66.666666%;
        flex-basis: 66.666666%;
        max-width: 66.666666%;
    }

    .col-lg-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        -ms-flex-preferred-size: 83.333333%;
        flex-basis: 83.333333%;
        max-width: 83.333333%;
    }

    .col-lg-11 {
        -ms-flex-preferred-size: 91.666666%;
        flex-basis: 91.666666%;
        max-width: 91.666666%;
    }

    .col-lg-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-lg-offset-0 {
        margin-left: 0;
    }

    .col-lg-offset-1 {
        margin-left: 8.333333%;
    }

    .col-lg-offset-2 {
        margin-left: 16.666666%;
    }

    .col-lg-offset-2-3 {
        margin-left: 20%;
    }

    .col-lg-offset-3 {
        margin-left: 25%;
    }

    .col-lg-offset-4 {
        margin-left: 33.333333%;
    }

    .col-lg-offset-5 {
        margin-left: 41.666666%;
    }

    .col-lg-offset-6 {
        margin-left: 50%;
    }

    .col-lg-offset-7 {
        margin-left: 58.333333%;
    }

    .col-lg-offset-8 {
        margin-left: 66.666666%;
    }

    .col-lg-offset-9 {
        margin-left: 75%;
    }

    .col-lg-offset-10 {
        margin-left: 83.333333%;
    }

    .col-lg-offset-11 {
        margin-left: 91.666666%;
    }

    .start-lg {
        -ms-flex-pack: start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }

    .center-lg {
        -ms-flex-pack: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }

    .end-lg {
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }

    .top-lg {
        -ms-flex-align: start;
        -webkit-box-align: start;
        align-items: flex-start;
    }

    .middle-lg {
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;
    }

    .bottom-lg {
        -ms-flex-align: end;
        -webkit-box-align: end;
        align-items: flex-end;
    }

    .around-lg {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    .between-lg {
        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }

    .first-lg {
        -ms-flex-order: -1;
        -webkit-box-ordinal-group: 0;
        order: -1;
    }

    .last-lg {
        -ms-flex-order: 1;
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
}

@media only screen and (min-width: $xlg-min) {
    .container {
        max-width: $xlg-container-width;
    }

    .container-wide {
        max-width: $xlg-container-wide-width;
    }

    .col-xlg,
    .col-xlg-1,
    .col-xlg-2,
    .col-xlg-2-3,
    .col-xlg-3,
    .col-xlg-4,
    .col-xlg-5,
    .col-xlg-6,
    .col-xlg-7,
    .col-xlg-8,
    .col-xlg-9,
    .col-xlg-10,
    .col-xlg-11,
    .col-xlg-12 {
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col-xlg {
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-xlg-auto {
        width: auto;
        max-width: none;
    }

    .col-xlg-1 {
        -ms-flex-preferred-size: 8.333333%;
        flex-basis: 8.333333%;
        max-width: 8.333333%;
    }

    .col-xlg-2 {
        -ms-flex-preferred-size: 16.666666%;
        flex-basis: 16.666666%;
        max-width: 16.666666%;
    }

    .col-xlg-2-3 {
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }

    .col-xlg-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-xlg-4 {
        -ms-flex-preferred-size: 33.333333%;
        flex-basis: 33.333333%;
        max-width: 33.333333%;
    }

    .col-xlg-5 {
        -ms-flex-preferred-size: 41.666666%;
        flex-basis: 41.666666%;
        max-width: 41.666666%;
    }

    .col-xlg-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-xlg-7 {
        -ms-flex-preferred-size: 58.333333%;
        flex-basis: 58.333333%;
        max-width: 58.333333%;
    }

    .col-xlg-8 {
        -ms-flex-preferred-size: 66.666666%;
        flex-basis: 66.666666%;
        max-width: 66.666666%;
    }

    .col-xlg-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-xlg-10 {
        -ms-flex-preferred-size: 83.333333%;
        flex-basis: 83.333333%;
        max-width: 83.333333%;
    }

    .col-xlg-11 {
        -ms-flex-preferred-size: 91.666666%;
        flex-basis: 91.666666%;
        max-width: 91.666666%;
    }

    .col-xlg-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-xlg-offset-0 {
        margin-left: 0;
    }

    .col-xlg-offset-1 {
        margin-left: 8.333333%;
    }

    .col-xlg-offset-2 {
        margin-left: 16.666666%;
    }

    .col-xlg-offset-2-3 {
        margin-left: 20%;
    }

    .col-xlg-offset-3 {
        margin-left: 25%;
    }

    .col-xlg-offset-4 {
        margin-left: 33.333333%;
    }

    .col-xlg-offset-5 {
        margin-left: 41.666666%;
    }

    .col-xlg-offset-6 {
        margin-left: 50%;
    }

    .col-xlg-offset-7 {
        margin-left: 58.333333%;
    }

    .col-xlg-offset-8 {
        margin-left: 66.666666%;
    }

    .col-xlg-offset-9 {
        margin-left: 75%;
    }

    .col-xlg-offset-10 {
        margin-left: 83.333333%;
    }

    .col-xlg-offset-11 {
        margin-left: 91.666666%;
    }

    .start-xlg {
        -ms-flex-pack: start;
        -webkit-box-pack: start;
        justify-content: flex-start;
        text-align: start;
    }

    .center-xlg {
        -ms-flex-pack: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
    }

    .end-xlg {
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
        text-align: end;
    }

    .top-xlg {
        -ms-flex-align: start;
        -webkit-box-align: start;
        align-items: flex-start;
    }

    .middle-xlg {
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;
    }

    .bottom-xlg {
        -ms-flex-align: end;
        -webkit-box-align: end;
        align-items: flex-end;
    }

    .around-xlg {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    .between-xlg {
        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }

    .first-xlg {
        -ms-flex-order: -1;
        -webkit-box-ordinal-group: 0;
        order: -1;
    }

    .last-xlg {
        -ms-flex-order: 1;
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
}