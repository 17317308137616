.horizontal-bar {
    $this: &;
    
    @extend .flex, .flex--center-y, .flex--nowrap, .flex--space-between;
    margin-top: -5px;
    margin-bottom: -5px; 
    max-width: 100%;
    width: 100%;
    
    > #{$this}__item {
        margin-top: 5px;
        margin-bottom: 5px;
        max-width: 100%;
        
        &:first-child {
            margin-right: 25px;
            
            @include max-screen($xxs-max) {
                margin-right: 15px;
            }
        }
    }
    
    &--wrap {
        @extend .flex--wrap;
    }
    
    &--wrap-sm {
        @include max-screen($sm-max) {
            flex-wrap: wrap;
            
            > #{$this}__item {
                width: 100%;
                //margin-top: 10px;
                
                &:first-child {
                    margin-right: 0;
                    //margin-top: 5px;
                }
            }
        }
    }
    
    &--wrap-xs {
        @include max-screen($xs-max) {
            flex-wrap: wrap;
            
            > #{$this}__item {
                width: 100%;
                //margin-top: 10px;
                
                &:first-child {
                    margin-right: 0;
                    //margin-top: 5px;
                }
            }
        }
    }
    
    &--wrap-xxs {
        @include max-screen($xxs-max) {
            flex-wrap: wrap;
            
            > #{$this}__item {
                width: 100%;
                //margin-top: 10px;
                
                &:first-child {
                    margin-right: 0;
                    //margin-top: 5px;
                }
            }
        }
    }
}
