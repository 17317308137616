.select2-selection--single {
    background-color: #fff;
    border: 2px solid $key-color-2;
    border-radius: 0;
    @include transition();


    .sidenav-select & {
        background-color: $key-color-1;
    }

    span[title="Wybierz"] {
        color: #131127;
        opacity: 0.3;


    }

    .select2-selection__rendered {
        color: $key-color-1;
        line-height: 54px;

        .sidenav-select & {
            color: $white-color;
        }
    }

    .select2-selection__clear {
        cursor: pointer;
        float: right;
        font-weight: bold;
    }

    .select2-selection__placeholder {
        color: #131127;
        opacity: 0.3;

    }

    .select2-selection__arrow {
        @extend .flex, .flex--center-x, .flex--center-y;
        width: 60px;
        height: 57px;
        position: absolute;
        top: 1px;
        right: 1px;

        b {
            /* 
            border-color: #888 transparent transparent transparent;
            border-style: solid;
            border-width: 5px 4px 0 4px;

            height: 0;
            left: 50%;

            margin-left: -4px;
            margin-top: -2px;

            position: absolute;

            top: 50%;
            width: 0; 
            */
            margin-top: 1px;

            line-height: 1;
            @include transition($duration: .2s);
            position: relative;
            transform-origin: center center;
            font-size: 8px;
            color: $key-color-1;

            &::before {
                font-family: $project-font-family-icon;
                content: "\e905";
            }

            .sidenav-select & {
                color: $white-color;
            }
        }
    }
}

&[dir="rtl"] {
    .select2-selection--single {
        .select2-selection__clear {
            float: left;
        }

        .select2-selection__arrow {
            left: 1px;
            right: auto;
        }
    }
}

&.select2-container--disabled {
    .select2-selection--single {
        background-color: #f2f2f2;
        border-color: #d9d9d9;
        cursor: default;

        .select2-selection__clear {
            display: none;
        }

        .select2-selection__rendered {
            color: #b4b4b4;
        }

    }

    .select2-selection__arrow {
        opacity: 0.3;
    }
}

&.select2-container--focus:not(.select2-container--disabled),
&.select2-container--open:not(.select2-container--disabled) {
    .select2-selection--single {
        border-color: $key-color-1;
        box-shadow: 0 0 15px 0 rgba($key-color-1, 0.1);
    }
}

&.select2-container--open {
    .select2-selection--single {
        .select2-selection__arrow {
            b {
                /* border-color: transparent transparent #888 transparent;
                border-width: 0 4px 5px 4px; */

                transform: rotate(180deg)
            }
        }
    }
}
