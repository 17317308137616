.mp-flex-wrapper {
	position: relative;
	display: flex;
	align-items: stretch;
	justify-content: space-between;

	@include max-screen($md-max) {
		justify-content: center;
		flex-direction: column;
	}

	&__left {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;

		@include max-screen($md-max) {
			// order: 3;
		}

		&.extended {
			border-bottom: none;
		}
	}

	&__center {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
	}

	&__right {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;

		@include max-screen($md-max) {
			display: none;
			order: 4;
		}
	}

	&__small {
		max-width: 415px;
		margin: 50px auto;
	}

	.tabs {
		display: none;

		@include max-screen($md-max) {
			margin: 40px 0 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			order: 2;
		}

		&__item {
			position: relative;
			font-size: 19px;
			font-weight: $default-font-weight;
			color: #333333;
			padding: 0 33px;
			padding-bottom: 12px;
			border: none;
			background: none;
			box-shadow: none;
			cursor: pointer;

			&::before {
				position: absolute;
				content: '';
				bottom: 0;
				left: 0;
				width: 100%;
				height: 3px;
				opacity: 0.21;
				background-color: #364a59;
			}

			&.active {
				font-weight: $default-font-weight-bold;

				&::before {
					opacity: 1;
				}
			}
		}
	}
}

.mp-flex-main {
	position: relative;
	padding: 40px 90px;
	min-width: 506px;
	height: 300px;
	margin: 0 13px;
	text-align: center;
	color: $white-color;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	@include max-screen($lg-max) {
		width: 360px;
		min-width: 360px;
		padding: 40px 30px;
	}

	@include max-screen($md-max) {
		position: relative;
		left: -30px;
		width: calc(100% + 60px);
		min-width: initial;
		margin: 0;
		order: 1;
	}

	&::before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgb(0, 0, 0);
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 1) 0%,
			rgba(0, 0, 0, 0) 60%
		);
		z-index: -1;
	}

	&__title {
		color: $white-color;
		font-size: 30px;
		font-weight: $default-font-weight-bold;
		margin-bottom: 15px;
	}

	&__text {
		color: $white-color;
		font-size: 18px;
		text-align: center;
		font-weight: $default-font-weight-light;
	}
}

.mp-flex-white {
	position: relative;
	padding: 40px 30px;
	background-color: $white-color;
	border: 1px solid $key-color-7;

	&__title {
		font-size: 1.125rem;
		color: $black-color;
		font-weight: $default-font-weight-bold;
		margin-bottom: 1.5625rem;
	}

	&__text {
		font-size: 14px;
		color: $black-color;
		font-weight: $default-font-weight;
		margin: auto 0;

		@include max-screen($md-max) {
			margin-bottom: 20px;
		}
	}

	&__wrapper {
		margin-top: auto;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		@include max-screen(400px) {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}

		a {
			margin-right: 15px;
			@include max-screen(400px) {
				margin-right: 0;
			}
		}

		a:last-child {
			margin-left: auto;
			margin-right: 0;

			@include max-screen($lg-max) {
				margin-top: 15px;
			}
		}
	}

	&__link {
		font-size: 14px;
		font-weight: $default-font-weight-semibold;
		color: $black-color;
		@include transition();

		@include hover() {
			color: $key-color-5;
		}
		@include max-screen(400px) {
			margin-bottom: 5px;
		}
	}

	&__subtitle {
		font-size: 14px;
		text-transform: uppercase;
		color: $black-color;
		font-weight: $default-font-weight-semibold;
		margin-bottom: 23px;
	}

	&__hidden-container {
		display: none;
		position: absolute;
		width: calc(100% + 2px);
		left: -1px;
		top: 100%;
		padding: 0 30px 0 30px;
		background-color: $white-color;
		border: 1px solid $key-color-7;
		border-top: none;
		z-index: 4;
	}
}

.mp-flex-white__hidden-container.js-mp-white-hidden-login {
	display: none;
	position: absolute;
	width: calc(100% + 2px);
	left: -1px;
	top: 100%;
	padding: 0 30px 0 30px;
	background-color: $white-color;
	border: 1px solid $key-color-7;
	border-top: none;
	z-index: 4;

	.extended.login & {
		display: flex;
		flex-direction: column;
	}
}

.mp-flex-white__hidden-container.js-mp-white-hidden-register {
	display: none;
	position: absolute;
	width: calc(100% + 2px);
	left: -1px;
	top: 100%;
	padding: 0 30px 0 30px;
	background-color: $white-color;
	border: 1px solid $key-color-7;
	border-top: none;
	z-index: 4;

	.extended.register & {
		display: flex;
		flex-direction: column;
	}
}

.mp-flex-white__hidden-container.js-mp-white-hidden-password {
	display: none;
	position: absolute;
	width: calc(100% + 2px);
	left: -1px;
	top: 100%;
	padding: 0 30px 0 30px;
	background-color: $white-color;
	border: 1px solid $key-color-7;
	border-top: none;
	z-index: 4;

	.extended.password & {
		display: flex;
		flex-direction: column;
	}
}

.tooltip-container {
	padding-top: 10px;
	position: relative;

	&__img {
		cursor: pointer;
		&:hover {
			+ .tooltip-container__tooltip {
				opacity: 1;
				height: auto;
				width: auto;
				visibility: visible;
			}
		}
	}
	&__tooltip {
		opacity: 0;
		transition: 0.4s ease-in-out;
		position: absolute;
		top: -30px;
		border-radius: 10px;
		left: 50%;
		transform: translateX(-50%);
		background-color: #2d2d2d;
		font-size: 12px;
		color: #fff;
		white-space: nowrap;
		padding: 10px;
		overflow: hidden;
		visibility: hidden;
	}
}

.categories-box {
	width: 100%;
	border: 1px solid $key-color-7;
	background-color: $white-color;

	&__inner {
		position: relative;
		padding: 37px 34px 30px 34px;

		@include max-screen($xxs-max) {
			padding: 30px 15px;
		}
	}

	&__main-categories {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		@include max-screen($sm-max) {
			justify-content: stretch;
			width: 100%;
		}

		.main-category {
			width: 152px;
			height: 57px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $key-color-7;
			background-color: $key-color-8;
			color: $black-color;
			font-size: 15px;
			font-weight: $default-font-weight-semibold;
			margin-right: 10px;
			@include transition();

			@include hover() {
				border-color: $key-color-1;
				background-color: $key-color-1;
				color: $white-color;
			}

			&.active {
				border-color: $key-color-1;
				background-color: $key-color-1;
				color: $white-color;
			}

			@include max-screen($sm-max) {
				width: 100%;
				justify-self: stretch;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	&__wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}

	&__logo {
		width: 97px;
		height: 87px;
		margin-left: 100px;

		@include max-screen($md-max) {
			display: none;
		}

		img {
			object-fit: scale-down;
		}
	}

	&__title {
		min-width: 277px;
		font-size: 22px;
		font-weight: $default-font-weight-bold;
		color: #252525;
		margin-bottom: 17px;
	}

	&__search-container {
		position: absolute;
		top: 20px;
		right: 30px;

		@include max-screen($md-max) {
			position: relative;
			top: initial;
			right: initial;
			margin-top: 30px;
			width: 100%;
		}
	}

	&__target-categories {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	&__small-category {
		border: none;
		background: none;
		box-shadow: none;
		padding: 0;
		cursor: pointer;
		white-space: nowrap;
		position: relative;
		font-size: 15px;
		color: #252525;
		margin-right: 13px;
		font-weight: $default-font-weight;
		margin-bottom: 5px;
		@include transition();

		&::before {
			position: absolute;
			content: '';
			bottom: 0;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #252525;
			opacity: 0;
			@include transition();
		}

		@include hover() {
			&::before {
				opacity: 1;
			}
		}

		&.active {
			font-weight: $default-font-weight-semibold;

			&::before {
				opacity: 1;
			}
		}
	}

	&__sub-categories {
		position: relative;
		//@include translate(0, -25px);
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 10px 0 20px;

		@include max-screen($md-max) {
			margin: 30px 0;
		}
		@include max-screen($xxs-max) {
			flex-direction: column;
		}

		.sub-category {
			position: relative;
			font-size: 20px;
			color: #252525;
			margin-right: 20px;
			padding-bottom: 5px;
			@include transition();

			@include max-screen($xxs-max) {
				margin-bottom: 10px;
			}

			&::before {
				position: absolute;
				content: '';
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: #252525;
				opacity: 0;
				@include transition();
			}

			&.active {
				font-weight: $default-font-weight-bold;

				&::before {
					opacity: 1;
				}
			}
		}
	}
}

.gallery-switcher-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@include max-screen($xxs-max) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	form {
		width: 272px;
		margin-left: 30px !important;

		@include max-screen($xxs-max) {
			margin-left: 0 !important;
			width: 100%;
		}
	}
}

.gallery-switcher {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	@include max-screen($xxs-max) {
		margin-bottom: 30px;
		display: none;
	}

	&__switch {
		color: #d8d8d8;
		font-size: 15px;
		margin-right: 15px;

		@include max-screen($xxs-max) {
			margin: 0 15px;
		}

		&.active {
			color: $key-color-1;
		}
	}
}

.form-desktop {
	display: block;

	@include max-screen($sm-max) {
		display: none;
	}
}

.form-mobile {
	display: none;

	@include max-screen($sm-max) {
		margin-top: 30px;
		display: block;
	}
}
