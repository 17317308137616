@include keyframes(scroll-down) {
    0% {
        bottom: -50px;
    }
    50% {
        bottom: -55px;
    }
    100% {
        bottom: -50px;
    }
}

@include keyframes(show-alert-alt) {
    0% {
        opacity:0;
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@include keyframes(fade-in) {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(slide-up) {
    0% {
        transform: translateY(1%);
    }
    100% {
        transform: translateY(0);
    }
}

@include keyframes(rotate) {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@include keyframes(rotate-mobile-device) {
    0%   {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    20%  {
        transform: translate(-50%, -50%) rotate(90deg);
    }
    75%  {
        transform: translate(-50%, -50%) rotate(90deg);
    }
    85% {
        transform: translate(-50%, -50%) rotate(0deg);
        
    }
}

@include keyframes(scale-in-out) {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
        
    }
}